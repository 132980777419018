import React, {createContext, useEffect, useReducer} from "react";

// Create GlobalContext Object
export const GlobalContext = createContext();
  
// Create a provider for components to consume and subscribe to changes
export const GlobalContextProvider = (props) => {
  const initialState = {
    content: {
      locale: "en",
      modal: {
        modalTitle: "",
        modalContent: "",
        modalMessage: "",
        isModalVisible: false    
      }
    }
  };

  const reducer = (state, action) => {
    console.log("reducer", state.content);
    return {
      content: JSON.parse(JSON.stringify(state.content))
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    console.log('useEffect GlobalContextProvider context: ', initialState);
  });

  return (
    <GlobalContext.Provider value={[state, dispatch]}>
      {props.children}
    </GlobalContext.Provider>
  )
}
