import React, { useRef, useState, useEffect, useContext, } from "react";
import { MessageModal } from "../components/MessageModal";
import { GlobalContext } from "../libs/globalContext";
import { FormattedMessage, IntlProvider } from "react-intl";
import en from "../lang/en";
import fr from "../lang/fr";

function ModalContainer(props) {

    const [isModalVisible, setModalVisible] = useState(false);
    const [locale, setLocale] = useState("en");

    const modalTitle = useRef("");
    const modalContent = useRef("");
    const modalMessage = useRef("");
    const [globalState, dispatch] = useContext(GlobalContext);

    useEffect(() => {
        console.log('ModalContainer useEffect globalContext: ', globalState);
        setModalVisible(globalState.content.modal.isModalVisible);
        modalTitle.current = globalState.content.modal.modalTitle;
        modalContent.current = globalState.content.modal.modalContent;
        modalMessage.current = globalState.content.modal.modalMessage;
        setLocale(globalState.content.locale);
      }, [globalState]);

      useEffect(() => {
        // console.log("useEffect2", isModalVisible);
      }, [isModalVisible]);

    return (
        <React.Fragment>
            <IntlProvider messages={locale === "fr" ? fr : en} locale={locale} defaultLocale="en">
            { isModalVisible && 
                <MessageModal 
                  title={<FormattedMessage id={modalTitle.current} />}
                  content={<FormattedMessage id={modalContent.current} />}
                  message={modalMessage.current}
                  modalVisible={isModalVisible}
                  setModalVisible={setModalVisible}
                />
            }
            {props.children}
            </IntlProvider>
        </React.Fragment>
        );
}

export default ModalContainer;