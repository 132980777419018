import {AppFrame, MenuButton, MobileMenuButton} from "./AppFrame";
import React, {useContext, useState} from "react";
import {Redirect, Route, useLocation} from "react-router-dom";

import {IntlProvider} from 'react-intl';
import en from "../lang/en";
import fr from "../lang/fr";
import {useAppContext} from "../libs/contextLib";
import {useHistory} from "react-router-dom";
import {useIntl} from 'react-intl';
import {GlobalContext} from "../libs/globalContext";

function TopMenuElements(props) {
    const {formatMessage} = useIntl();
    const history = useHistory();

    return (
        <React.Fragment>
            {['overview', 'impact', 'carbon', 'investments', 'projects', 'profile'].map((item, idx) =>
                <MenuButton
                    key={item}
                    label={formatMessage({'id': 'menu.' + item + '.label'})}
                    onClick={() => history.push(formatMessage({'id': 'menu.' + item + '.path'}))}/>)
            }
            <MenuButton label={formatMessage({'id': 'menu.logout.label'})} onClick={props.handleLogout}/>
        </React.Fragment>
    );
}

function MobileTopMenuElements(props) {
    const {formatMessage} = useIntl();
    const history = useHistory();

    const icons = {
        "overview": "M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z",
        "impact": "M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z",
        "docs": "M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z",
        "carbon": "M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z",
        "projects": "M8.416.223a.5.5 0 0 0-.832 0l-3 4.5A.5.5 0 0 0 5 5.5h.098L3.076 8.735A.5.5 0 0 0 3.5 9.5h.191l-1.638 3.276a.5.5 0 0 0 .447.724H7V16h2v-2.5h4.5a.5.5 0 0 0 .447-.724L12.31 9.5h.191a.5.5 0 0 0 .424-.765L10.902 5.5H11a.5.5 0 0 0 .416-.777l-3-4.5zM6.437 4.758A.5.5 0 0 0 6 4.5h-.066L8 1.401 10.066 4.5H10a.5.5 0 0 0-.424.765L11.598 8.5H11.5a.5.5 0 0 0-.447.724L12.69 12.5H3.309l1.638-3.276A.5.5 0 0 0 4.5 8.5h-.098l2.022-3.235a.5.5 0 0 0 .013-.507z",
        "investments": "M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
        "profile": "M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z",
        "logout": "M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
    }

    return (
        <React.Fragment>
            {['overview', 'impact', 'carbon', 'investments', 'projects', 'profile'].map((item, idx) =>
                <MobileMenuButton
                    key={idx}
                    label={formatMessage({'id': 'menu.' + item + '.label'})}
                    icon={icons[item]}
                    onClick={() => {
                        props.setIsMobileMenuVisible(false);
                        history.push(formatMessage({'id': 'menu.' + item + '.path'}))
                    }}/>)
            }
            <MobileMenuButton
                label={formatMessage({'id': 'menu.logout.label'})}
                icon={icons['logout']}
                onClick={() => {
                    props.setIsMobileMenuVisible(false);
                    props.handleLogout()
                }}/>
        </React.Fragment>
    );
}

// exact path="/en" component={LoadableHome} locale="en" handleLogout={props.handleLogout}

export default function AuthenticatedRoute({children, locale, handleLogout, ...props}) {
    const {pathname, search} = useLocation();
    const {isAuthenticated} = useAppContext();
    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
    const [globalState] = useContext(GlobalContext);
    globalState.content.locale = locale;
    // dispatch({type: "UPDATE_OBJECT", payload: globalState});

    return (
        <IntlProvider messages={locale === "fr" ? fr : en} locale={locale} defaultLocale="en">
            <AppFrame
                setIsMobileMenuVisible={setIsMobileMenuVisible}
                isMobileMenuVisible={isMobileMenuVisible}
                menu={<TopMenuElements
                    handleLogout={handleLogout}/>}
                mobileMenu={<MobileTopMenuElements
                    handleLogout={handleLogout}
                    setIsMobileMenuVisible={setIsMobileMenuVisible}/>}
                contents={
                    <Route {...props}>
                        {isAuthenticated ? (
                            children
                        ) : (
                            <Redirect to={
                                `/login?redirect=${pathname}${search}`
                            }/>
                        )}
                    </Route>
                }
            />
        </IntlProvider>
    );
}