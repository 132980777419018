const fr = {
    hello_world: "Bonjour le monde !",
  
    "loading": "Chargement{br}en cours",
    "error.title": "Erreur!",
    "error.message": "Une erreur s'est produite en tentant d'effectuer cette action.",

    "menu.home.path": "/",
    "menu.overview.label": "Sommaire",
    "menu.overview.path": "/",
    "menu.impact.label": "Impacts",
    "menu.impact.path": "/impact",
    "menu.docs.label": "Documents",
    "menu.docs.path": "/documents",
    "menu.carbon.label": "Carboneutralité",
    "menu.carbon.path": "/carbon",
    "menu.projects.label": "Projets",
    "menu.projects.path": "/projects",
    "menu.investments.label": "Investissements",
    "menu.investments.path": "/investissements",
    "menu.profile.label": "Profil",
    "menu.profile.path": "/profil",
    "menu.logout.label": "Déconnexion",

    "lang": "fr",
    "language.other": "English",

    "page_link.investment": "/investissement-pour-le-futur",

    "login.url": "/login",
    "login.url.otherlang": "/en/login",
    "signup.url.otherlang": "/en/signup",
    "resetpwd.url.otherlang": "/en/login/reset",
    "login.title": "Espace investisseur",
    "login.login_divider": "Connexion à votre compte",
    "login.fields.email": "Adresse courriel",
    "login.fields.password": "Mot de passe",
    "login.not_member_yet": "Pas encore de compte?",
    "login.not_member_yet.url": "https://treesoflives.com/investir-comme-particulier",
    "login.forgot_pwd": "Oublié votre mot de passe?",
    "login.signin": "Connexion",
    "login.newPassword.desc": "Avant de continuer, vous devez choisir un nouveau mot de passe:",
    "login.newPassword.strength.label": "Votre mot de passe doit respecter les règles suivantes:",
    "login.newPassword.strength.1.label": "Long d'au moins 12 caractères",
    "login.newPassword.strength.2.label": "Avoir au moins une lettre majuscule et une lettre minuscule",
    "login.newPassword.strength.3.label": "Avoir au moins un chiffre",
    "login.newPassword.strength.4.label": "Les deux mots de passe doivent être équivalents",
    "login.fields.newPassword": "Nouveau mot de passe",
    "login.fields.newPassword2": "Entrez votre nouveau mot de passe de nouveau",
    "login.update_password": "Mettre à jour",

    "pwd_reset.description": "Si vous avez oublié votre mot de passe, SVP entrez votre adresse courriel ci-desous et nous vous enverons un code de confirmation.",
    "pwd_reset.u_know_pwd": "Vous connaissez votre mot de passe?",
    "pwd_reset.reset_divider": "Réinitialiser votre mot de passe",
    "pwd_reset.send_label": "Envoyer le code de réinitialisation",
    "pwd_reset.resetpwd_label": "Réinitialiser votre mot de passe",
    "pwd_reset.login_new_pwd": "Se connecter avec votre nouveau mot de passe",
    "pwd_reset.resend_code.label": "Renvoyer le code",
    "pwd_reset.fields.code.label": "Code de confirmation",
    "pwd_reset.fields.code.placeholder": "SVP vérifiez vos courriels pour votre code de confirmation",
    "pwd_reset.success.message": "Votre mot de passe a été mis à jour.",

    "home.title": "État de vos investissements{br}sur {years} année(s)",
    "statsblock.elem.1.label": "Valeur de votre investissement",
    "statsblock.elem.2.label": "Séquestration de carbone",
    "statsblock.elem.3.label": "Gains en capital naturel",
    "statsblock.elem.4.label": "Dommages économiques évités",
    "statsblock.elem.5.label": "Revenus des propriétaires de terres",
    "statsblock.elem.6.label": "Familles impliquées",
    "statsblock.elem.7.label": "Hectares restaurés",
    "statsblock.elem.8.label": "Hectares protégés",
    "statsblock.elem.9.label": "Valeur des actifs biologiques",
    "statsblock.elem.10.label": "Argent distribué",
    "statsblock.stats.tocome": "bientôt disponible",

    "home.openlead.title": "Votre demande d'investissement",
    "home.openlead.header": "Voici les étapes auxquelles vous pouvez vous attendre lors du traitement de votre demande d'investissement.",
    "home.openlead.steps.1.title": "Rendez-vous avec un courtier",
    "home.openlead.steps.1.desc": "Nous travaillons avec un courtier du marché dispensé qui vérifie l’éligibilité de tout nouvel investisseur. Le courtier prendra connaissance de votre profil d’investisseur lors de cet appel téléphonique pour s’assurer que l’investissement correspond à votre profil et vos objectifs. Ce sera aussi une occasion pour vous de poser les dernières questions que vous aurez concernant le fonds.",
    "home.openlead.steps.2.title": "Documentation reçu",
    "home.openlead.steps.2.desc": "Votre document a été reçue et sera soumise à une vérification de conformité.",
    "home.openlead.steps.3.title": "Demande validée",
    "home.openlead.steps.3.desc": "Votre dossier a été approuvé par la conformité.",
    "home.openlead.steps.4.title": "Ouverture du compte Computershare",
    "home.openlead.steps.4.desc": "Vous serez invité à vous ouvrir un compte chez Computershare.",
    "home.openlead.steps.5.title": "Fonds reçus",
    "home.openlead.steps.5.desc": "Félicitations, vos fonds ont été reçu et vous êtes maintenant un investisseur dans TreesOfLives.",
    "home.openlead.steps.6.title": "Reçu",
    "home.openlead.steps.6.desc": "Un reçu vous sera émis sous peu et vous le recevrez par la poste.",
    "home.warning": "<p>*Avertissement: Les rendements prévus sont basés sur des données de projets similaires réalisés dans les mêmes régions où Viridis Terra effectuera ses activités de restauration. Cependant, les rendements ne sont pas garantis et peuvent varier considérablement en raison de différents facteurs dont les conditions climatiques et le choix des modèles d'aménagement des terres par les propriétaires. Voyez les facteurs de risque tels qu'énoncés dans notre <legal>avis d’offre</legal>.</p>",

    "home.projections.you": "Pour vous",
    "home.projections.community": "Pour les communautés",
    "home.projections.planet": "Pour la planète",

    "home.projections.title": "Projections",
    "home.projections.subtitle1": "Carbone",
    "home.projections.subtitle2": "Financier",
    "home.projections.subtitle3": "Biodiversité",
    "home.projections.subtitle4": "Social",
    "home.projections.1.title": "Séquestration de carbone",
    "home.projections.1.description": "Il s’agit du nombre de tonnes de CO2 équivalent que vous avez contribué à séquestrer et qui correspond également à votre quantité de crédits de compensation carbone certifiés. Le carbone séquestré provient des nouvelles forêts et des forêts protégées grâce à votre investissement.",
    "home.projections.2.title": "Gains en capital naturel",
    "home.projections.2.description": "Le capital naturel représente la valeur économique des biens et services provenant de la nature. Le bois, la nourriture, la biodiversité, la qualité de l’eau et de l’air en sont des exemples. Le gain en capital naturel peut être négatif lors des premières années puisque le travail préliminaire de préparation terrain peut exiger l’élimination d’une partie de la végétation existante pour laisser de l’espace à de la plus noble. Lorsque les nouvelles forêts émergent et que l’écosystème se régénère, les gains en capital naturel augmentent considérablement.",
    "home.projections.3.title": "Dommages économiques évités",
    "home.projections.3.description": "Ce graphique présente le montant correspondant aux dommages environnementaux évités grâce à votre investissement. Ces dommages causés par les dérèglements climatiques se traduisent en données économiques. Il peut s’agir d'effets sur la santé humaine, de dommages matériels dus aux inondations, de catastrophes naturelles ou encore de conséquences négatives sur la productivité agricole.",
    "home.projections.4.title": "Revenus des propriétaires de terres",
    "home.projections.4.description": "Ce montant correspond aux revenus que les propriétaires des terres impliqués dans nos projets reçoivent, en fonction de la valeur de votre investissement. À titre comparatif, le salaire moyen annuel au Pérou était de 4 693,32 $ US en 2021. Source: https://preciosmundi.com/peru/precio-vivienda-salarios",
    "home.projections.5.title": "Familles impliquées",
    "home.projections.5.description": "Chaque fois que nous restaurons une terre, un producteur et sa famille en bénéficient. Que ce soit par la création d’un revenu familial plus intéressant ou d’enfants qui poursuivent des études supérieures, ce sont des familles complètes qui profitent des retombées des projets.",
    "home.projections.6.title": "Argent distribué",
    "home.projections.6.description": "Chaque année, vous recevrez des distributions (aussi appelées dividendes) en argent, correspondant à votre retour sur investissement provenant des profits des projets. Le retour sur l’investissement actualisé et le taux de rendement interne sont calculés en fonction des sommes distribuées.",
    "home.projections.6.note1": "TRI (taux de rentabilité interne): ",
    "home.projections.6.note2": "RSIA (retour sur investissement annualisé): ",
    "home.projections.6.note3": "FTA (flux de trésorerie actualisé): ",
    "home.projections.7.title": "Valeur des actifs biologiques",
    "home.projections.7.description": "En investissant dans TreesOfLives, vous obtenez des unités (actions) de la société en commandite qui possède une copropriété avec les propriétaires de terres sur les actifs biologiques reliés aux arbres établis sur les terres restaurées. Ces arbres produisent des biens et services à valeur commerciale à travers le temps qui sont vendus sur le marché créant ainsi une valeur monétaire dans le futur. La formule de flux de trésorerie actualisé avec un taux d'escompte de 20% a été considérée pour calculer la valeur des actifs. Dans l’éventualité où vous revendez vos unités, la valeur des actifs biologiques pourrait être, éventuellement, un point de départ pour les négociations avec d’autres investisseurs.",

    "home.messages.title": "Vos messages",
    "home.messages.1.title": "Bienvenue sur TreesOfLives!",
    "home.messages.1.desc": "<p>Bienvenue sur TreesOfLives. Vous êtes parmi les premiers investisseurs à avoir fait un geste concret dans la lutte contre le changement climatique. Pour cela, nous vous remercions. </p><p>De nombreuses sections de cette page ne sont pas encore terminées et nous travaillons sans relâche pour vous apporter des fonctionnalités intéressantes. Laissez-nous savoir ce que vous en pensez et ce que vous aimeriez voir !</p>",
    "home.messages.1.cta": "Laissez-nous savoir ce que vous pensez!",

    "impacts.title": "Impacts ODD (objectifs de développement durables de l'ONU)",
    "impacts.description": "À travers nos projets de restauration de terres dégradées, nous nous efforçons d’avoir le plus d’impacts possibles sur les dix-sept (17) objectifs de développement durable de l’ONU, qui se trouvent sur le chemin critique d'un monde sans pauvreté, d’une planète en santé et de la prospérité pour tous.",
    "impacts.description2": "Viridis terra croit fermement que « Les objectifs de développement durable nous donnent la marche à suivre pour parvenir à un avenir meilleur et plus durable pour tous. Ils répondent aux défis mondiaux auxquels nous sommes confrontés, notamment ceux liés à la pauvreté, aux inégalités, au climat, à la dégradation de l’environnement, à la prospérité, à la paix et à la justice. Les objectifs sont interconnectés et, pour ne laisser personne de côté, il est important d’atteindre chacun d’entre eux, et chacune de leurs cibles, d’ici à 2030. » - Source : Site Web des Nations Unies",

    "impacts.odd1.description": "<p><label1>0.1</label1> famille(s) impliquée(s)</p><p>Revenus des propriétaires fonciers : <label2>2 950 $</label2></p><p>Bénéfice commercial des propriétaires fonciers sur 30 ans : <label3>67 360 $</label3></p><p> Augmentation du revenu familial : à venir</p>",
    "impacts.odd2.description": "<p>Augmentation de 2X de la productivité des terres</p><p>Jardins familiaux de <label1>0,07</label1> ha</p><p>50 espèces comestibles plantées</p>",
    "impacts.odd3.description": "<p>Revenu familial supplémentaire pouvant être investi dans les soins : à venir</p><p>Diminution du nombre de personnes souffrant de troubles respiratoires</p>",
    "impacts.odd4.description": "<p><label1>4.8</label1> heures de formation/renforcement des capacités pour une gestion durable par an</p><p>% d'augmentation du nombre d'enfants (filles/garçons) scolarisés : à venir</p>" ,
    "impacts.odd5.description": "<p><label1>0.51</label1> femme(s) signataire(s) de contrats</p><p><label1>0.51</label1> femme(s) formée(s) en agroforesterie</p><p><label1>0.51</label1> personne(s) reçoit une formation à la masculinité positive</p><p>Augmentation des femmes en position de pouvoir : à venir</p>",
    "impacts.odd6.description": "<p>Augmentation de l'accès et de la qualité de l'eau</p><p><label1>0.76</label1> ha de zones humides et riveraines</p>",
    "impacts.odd7.description": "<p>Objectif : 100% de l'énergie utilisée est renouvelable</p><p>Lorsque inévitable, les GES émis sont directement compensés par des crédits carbone (0,2 T CO2 eq/ha)</p>",
    "impacts.odd8.description": "<p><label1>1.09</label1> emplois créés</p><p>Nombre d'associations créées ou renforcées : à venir</p>",
    "impacts.odd9.description": "<p>Conduit à <label1>660 $</label1> supplémentaires investis dans les infrastructures de production de biotechnologie</p><p>Augmentation de l'accès au réseau cellulaire dans les zones reculées : à venir</p>",
    "impacts.odd10.description": "<p>Plus de 8 communautés autochtones en tant que partenaires bénéficiant du projet</p><p>Disparités salariales réduites : à venir</p>",
    "impacts.odd11.description": "<p>Diminution de la migration urbaine : à venir</p><p><label1>100</label1> arbres supplémentaires donnés aux collectivités locales, <label2>400</label2> m2 de ville végétalisée</p>",
    "impacts.odd12.description": "<p>Produits issus de forêts et d'agroforêts</p><p>100% géotracés</p><p>100% zéro déforestation</p><p>100% certifiés ; qu'il s'agisse de crédits carbone (VERRA), de bioénergie (SBP), de bois (FSC), d'agroproduits (Rainforest Alliance, Fairtrade)</p>",
    "impacts.odd13.description": "<p><label1>1000</label1> tonnes de CO2 séquestrées et certifiées sur 30 ans dans des paysages restaurés et évitées d'être rejetées par la préservation des forêts</p>",
    "impacts.odd14.description": "<p>Réduction de la charge sédimentaire déposée dans les zones côtières grâce au contrôle de l'érosion des sols par la restauration et le reboisement : à venir</p>",
    "impacts.odd15.description": "<p><label1>0.7</label1> ha restaurés dans les systèmes productifs</p><p><label2>0.3</label2> ha restaurés dans les écosystèmes forestiers naturels</p><p><label1>0.7</label1> ha de forêt naturelle préservés</p><p>Augmentation de la valeur du capital naturel de <label3>4 786 $</label3> en moyenne par an</p>",
    "impacts.odd16.description": "<p>100% des plaintes reçues, traitées, résolues avec satisfaction de toutes les Parties</p><p>Nb de personnes formées à la résolution des conflits : à venir</p> <p>Tous les employés de VTP et VTI formés aux questions anti-corruption</p><p>Due diligence anti-corruption réalisé sur tous les partenaires</p>",
    "impacts.odd17.description": "<p>Des partenariats solides avec plus de 40 institutions des secteurs public, privé et de la société civile</p><p>Conduisent à <label1>1000 $</label1> supplémentaires investis dans le développement international dans des pays où les risques sont actuellement trop élevés pour y implanter l'IFLR (Projets intégrés de restauration de paysages forestiers)</p>",

    "projects.title": "Projets",
    "projects.activities.title": "Étapes en cours",
    "projects.description": "À mesure que les projets évoluent, vous aurez accès à des photos des forêts, agro forêts et de nos activités sur le terrain.",
    "projects.activity1.title": "Signature contrats avec propriétaires de terres",
    "projects.activity2.title": "Inventaire des terres restaurées",
    "projects.activity3.title": "Sélection des systèmes d’aménagement par les propriétaires de terres",
    "projects.activity4.title": "Production des biotechnologies",
    "projects.activity5.title": "Opérations terrain de restauration et reforestation",
    "projects.activity6.title": "Suivis réguliers de la croissance des actifs, vente des commodités et impacts des projets",
    "projects.activity1.description": "Une des étapes essentielle préalable au démarrage des projets est la signature des contrats stipulant les engagements de TreesOfLives, Viridis Terra et des propriétaires de terres. On s’assure ainsi d’un fonctionnement optimal et d’une bonne gestion des attentes entre toutes les Parties.",
    "projects.activity2.description": "Cette étape vise à connaitre l’utilisation actuelle et la capacité d’utilisation des terres. Pour y arriver, notre équipe fait l’inventaire de la végétation et l’analyse hydrologique des sols (la circulation de l’eau dans le sol). Ainsi, nous sommes en mesure de déterminer plus précisément lesquels de nos modèles d’aménagement sont le mieux adaptés à cet endroit spécifique soit le lopin de terre de notre partenaire.",
    "projects.activity3.description": "Une fois que la capacité d’utilisation des sols est connue, un dialogue est entamé entre le propriétaire de terres et les professionnels techniques sur le terrain en vue de déterminer quel système implanter. En collaboration avec les propriétaires de terres, nous choisissons les meilleurs systèmes d’aménagement de leurs terres. Nous prenons en considération le type de sol, la localisation et les méthodes de culture privilégiées par l’agriculteur.",
    "projects.activity4.description": "Une fois que nous sommes en mesure de déterminer les espèces végétales, en quelle quantité elles seront produites et les besoins en intrant organiques (terre, compost et engrais), nous pouvons en planifier l’approvisionnement ce qui nous permettra d’implanter les modèles adaptés d’aménagement des terres. Nous nous approvisionnons chez divers fournisseurs internationaux et nationaux ayant des connaissances et des technologies avancées dans le domaine.",
    "projects.activity5.description": "Celle ci est l’étape durant laquelle les systèmes d’aménagement des terres sont mis en place. C’est-à-dire que le travail concret de planter des arbres et restaurer les terres débute. Normalement, cette étape doit s’effectuer au début de la saison des pluies afin de s’assurer de la réussite du travail.",
    "projects.activity6.description": "Lorsqu’un projet est bien implanté, notre équipe terrain continuera d’effectuer un suivi régulier afin que les forêts croissent comme prévu. De plus, TreesOfLives vendra les produits de base issus des projets afin de générer votre retour sur investissement.",

    "projects.news.title": "Nouvelles",

    "projects.map.title": "Carte des travaux en cours",
    "projects.pictures.title": "Photos",
    "projects.videos.title": "Vidéos",
    "video.motion_design": "https://s3.ca-central-1.amazonaws.com/documents.treesoflives.com/DronesNuevoPalestinaJuin2021.mp4",
    "video.motion_design2": "https://s3.ca-central-1.amazonaws.com/documents.treesoflives.com/DronesSanAlejandroJuin2021.mp4",
    "projects.picture1.description": "Accumuler la masse organique sur le sol dans la parcelle échantillon",
    "projects.picture2.description": "Indication de la parcelle avec la boussole",
    "projects.picture3.description": "Le chef de brigade (Gustavo Dondo) donnant les indications pour l'inventaire",

    "investments.title": "Investissements",
    "investments.list.header.amount": "Montant",
    "investments.list.header.units": "Unités",
    "investments.list.header.state": "État",
    "investments.list.header.type": "Type",
    "investments.list.header.fund": "Fond",

    "investments.mardiinfo.warning": "Attention: Au moins un de vos investissements est inscrit par Mardi.info auprès de ComputerShare. Le rendement de ces invstissements pourrait être différent du fonds TreesOfLives, car il est inscrit dans un fonds mutuel. Pour plus de détails, veuillez contacter Dany Bergeron de Mardi.info (438-882-4464).",

    "investments.type.RRSP": "REER",
    "investments.type.TFSA": "CELI",
    "investments.type.UNREGISTERED": "Non enregistré",

    "investments.state.EXTERNAL": "Géré à l'externe",
    "investments.state.FUNDS_TRANSFER_TODO": "Virement en attente",
    "investments.state.FUNDS_TRANSFER_IN_PROGRESS": "Virement en cours",
    "investments.state.FUNDS_TRANSFER_DONE": "Virement complété",
    "investments.state.WAITING_FOR_INVESTOR_TRANSFER": "En attente du virement",

    "investment.newinvestment.title": "Souhaitez-vous{br}<green>investir</green> à nouveau?",
    "investment.newinvestment.link": "https://www.treesoflives.com/investissement-pour-le-futur/",
    "investment.newinvestment.button.label": "Investir à nouveau",

    "profile.sections.profile": "Profil",
    "profile.sections.email": "Courriel",
    "profile.sections.password": "Mot de passe",
    "profile.sections.notifications": "Alertes",
    "profile.profile.contact_info.title": "Information de contact",
    
    "profile.profile.fields.given_name": "Prénom",
    "profile.profile.fields.family_name": "Nom de famille",
    "profile.profile.fields.email": "Courriel actuel",
    "profile.profile.fields.phone_number": "Cellulaire",
    "profile.profile.fields.custom:phone_landline": "Téléphone fixe",
    "profile.profile.fields.street_address": "Adresse",
    "profile.profile.fields.city": "Ville",
    "profile.profile.fields.state": "Province / État",
    "profile.profile.fields.zip": "Code postal / Zip code",
    "profile.profile.fields.country": "Pays",
    "profile.profile.fields.country.values.canada": "Canada",
    "profile.profile.fields.country.values.usa": "États-Unis",
    "profile.profile.fields.locale": "Langue de communication",
    
    "profile.profile.fields.state.values.provinces.AB": "Alberta",
    "profile.profile.fields.state.values.provinces.BC": "Colombie-Britanique",
    "profile.profile.fields.state.values.provinces.MB": "Manitoba",
    "profile.profile.fields.state.values.provinces.NB": "Nouveau-Brunswick",
    "profile.profile.fields.state.values.provinces.NL": "Terre-Neuve-et-Labrador",
    "profile.profile.fields.state.values.provinces.NT": "Territoires du Nord-Ouest",
    "profile.profile.fields.state.values.provinces.NS": "Nouvelle-Écosse",
    "profile.profile.fields.state.values.provinces.NU": "Nunavut",
    "profile.profile.fields.state.values.provinces.ON": "Ontario",
    "profile.profile.fields.state.values.provinces.PE": "Île-du-Prince-Édouard",
    "profile.profile.fields.state.values.provinces.QC": "Québec",
    "profile.profile.fields.state.values.provinces.SK": "Saskatchewan",
    "profile.profile.fields.state.values.provinces.YT": "Yukon",

    "profile.profile.address.title": "Adresse",
    "profile.profile.other.title": "Autre",

    "profile.profile.update_button": "Mettre à jour",

    "profile.profile.email.resend_code": "Renvoyer le code",
    "profile.profile.fields.newEmail": "Nouveau courriel",
    "profile.profile.fields.confirmNewEmail": "Confirmation nouveau courriel",
    "profile.profile.email.email_no_match": "Le nouveau courriel et sa confirmation doivent comporter une valeur et correspondre.",
    "profile.profile.email.email_format_invalid": "Le format du courriel est invalide.",
    "profile.profile.email.code_sent_desc": "Un code de confirmation vous a été envoyé à l'adresse que vous nous avez fourni. SVP entrez le ci-dessous.",
    "profile.profile.fields.emailCode": "Code reçu par courriel",
    "profile.profile.fields.emailCode_invalid": "Le code fourni est invalide. SVP vérifiez que vous avez fourni le bon code.",
    "profile.profile.email.change_success": "Votre adresse courriel a été modifiée avec succès.",

    "profile.profile.fields.oldPassword": "Ancien mot de passe",
    "profile.profile.fields.password": "Nouveau mot de passe",
    "profile.profile.fields.confirmPassword": "Confirmation du nouveau mot de passe",
    "profile.profile.error.phone": "Le format du numéro de téléphone est invalide. Les numéros de téléphone doivent respecter ces règles de formatage : Un numéro de téléphone doit commencer par un signe plus (+), suivi immédiatement de l'indicatif du pays. Un numéro de téléphone ne peut contenir que le signe + et des chiffres. Vous devez supprimer tous les autres caractères d'un numéro de téléphone, tels que les parenthèses, les espaces ou les tirets (-) avant de soumettre la valeur au service. Par exemple, un numéro de téléphone basé aux États-Unis doit respecter ce format : +14325551212.",
    "profile.profile.change_success": "Votre profil a été modifié avec succès.",
    "profile.profile.password.change_success": "Votre mot de passe a été modifié avec succès.",

    "documents.available_soon": "Bientôt disponible",
    "documents.lookingforfund-legal": "Vous cherchez les documents légaux du fonds TreesOfLives?",
    "documents.lookingforfund-legal.link": "https://treesoflives.com/investissement-pour-le-futur#fund-legal-docs",

    "footer.consent": "En utilisant le site web ou en vous connectant à un compte sur la plateforme, vous acceptez d'être lié et de vous conformer à nos <legal>conditions d'utilisation et à nos avis de confidentialité</legal>.",
    "footer.consent.legal_link": "https://treesoflives.com/mentions-legales",
    "footer.copyright": "© 2021, TreesOfLives Inc. Tous droits réservés.",

    "carbonchart.title": "Vos émissions sur 30 ans",
    "carbonchart2.title": "La route vers la carboneutralité!",
    "carbonchart.description": "Lorsque vous investissez dans TreesOfLives, vous contribuez à retirer de l’atmosphère d’importantes quantités de gaz à effets de serre dont une portion peut servir à compenser pour vos propres émissions.",
    "carbonchart.description2": "Vous pouvez suivre l’accumulation des crédits carbone associés à votre investissement à l’aide de ce graphique.",
    "carbonchart.amount_to_invest": "Montant investi",
    "carbonchart.more": "Plus",
    "carbonchart.less": "Moins",
    "carbonchart.ca": "Canada",
    "carbonchart.us": "États-Unis",
    "carbonchart.eu": "Europe",
    "carbonchart.age.label": "Votre âge : ",
    "carbonchart.production.label":
        "Votre moyenne d'émissions annuelles (tonnes de CO2) : ",

}

export default fr;