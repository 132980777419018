import './Loading.css';

import { FormattedMessage } from "react-intl";
import { ReactComponent as TolIcon } from  '../logos/TOL_logo_vert.svg';

export function Loading() {
    return (
    <div className="min-h-screen flex justify-center bg-beige-base py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-lg w-48">
        <TolIcon className="heartbeat" />
        <h2 className="text-center text-2xl leading-9 font-extrabold text-gray-900">
          <FormattedMessage id="loading" 
            values={{
              br: <br />,
            }}
          />
        </h2>
      </div>
    </div>
    );
}