import React, {useEffect, useState} from "react";

import {AppContext} from "./libs/contextLib";
import {Auth} from "aws-amplify";
import ErrorBoundary from "./components/ErrorBoundary";
import {Helmet} from "react-helmet";
import Routes from "./Routes";
import {onError} from "./libs/errorLib";
import {GlobalContextProvider} from "./libs/globalContext";
import ModalContainer from "./containers/ModalContainer";

function App() {
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [isAuthenticated, userHasAuthenticated] = useState(false);

    useEffect(() => {
        onLoad();
    }, []);

    async function onLoad() {
        try {
            await Auth.currentSession();
            userHasAuthenticated(true);
        } catch (e) {
            if (e !== 'No current user') {
                onError(e);
            }
        }

        setIsAuthenticating(false);
    }

    async function handleLogout() {
        await Auth.signOut();
        userHasAuthenticated(false);
    }

    async function globalSignOut() {
        try {
            await Auth.signOut({global: true});
            userHasAuthenticated(false);
        } catch (error) {
            console.error('error signing out: ', error);
        }
    }


    function Header(props) {
        return (
            <Helmet>
                <title>TreesOfLives</title>
                <meta name="description" content="TreesOfLives - Investor Space"/>
                <meta charSet="utf-8"/>
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/apple-touch-icon.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/favicon-16x16.png"
                />
                <link rel="manifest" href="/site.webmanifest"/>
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5b8666"/>
                <meta name="msapplication-TileColor" content="#5b8666"/>
                <meta name="theme-color" content="#ffffff"/>
            </Helmet>
        );
    }


    if (!isAuthenticated) {
        return (
            <ErrorBoundary>
                <Header/>
                <AppContext.Provider value={{isAuthenticated, userHasAuthenticated}}>
                    <GlobalContextProvider>
                        <ModalContainer>
                            <Routes/>
                        </ModalContainer>
                    </GlobalContextProvider>
                </AppContext.Provider>
            </ErrorBoundary>
        );
    } else {
        return (
            <ErrorBoundary>
                <Header/>
                <AppContext.Provider value={{isAuthenticated, userHasAuthenticated}}>
                    <GlobalContextProvider>
                        <ModalContainer>
                            <Routes handleLogout={handleLogout}/>
                        </ModalContainer>
                    </GlobalContextProvider>
                </AppContext.Provider>
            </ErrorBoundary>
        )
    }
}

export default App;