import { FormattedMessage } from "react-intl";
import React from "react";
import { ReactComponent as TolLogo } from '../logos/TOL_logo.svg';
import { ReactComponent as TolLogoVertNoir } from '../logos/TOL_logo_horizontal_vert_noir.svg';
import { useHistory } from "react-router-dom";
import { useIntl } from 'react-intl';
import './AppFrame.css';

export function MenuButton(props) {
  return (
    <button
      className="inline-block text-base py-2 leading-none rounded text-activ-100 hover:text-activ-94 hover:bg-greentol-25 mt-4 lg:mt-0"
      onClick={props.onClick}
    >
      {props.label}
    </button>
  );
}

export function MobileMenuButton(props) {
  return (
    <button
      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
      onClick={props.onClick}
    >
      <svg className="flex-shrink-0 h-6 w-6 text-greentol-base" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path strokeLinecap={"round"} strokeLinejoin={"round"} strokeWidth={"2"} d={props.icon} />
      </svg>
      <span className="ml-3 text-base font-medium text-greentol-base">
        {props.label}
      </span>
    </button>
  );
}

function MobileMenu(props) {
  const { formatMessage } = useIntl();
  const history = useHistory();
  return (
    <div className="z-50 absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden">
      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-beige-base">
        <div className="pt-5 pb-6 px-5">
          <div className="flex items-center justify-between">
            <button onClick={() => {
              props.setIsMobileMenuVisible(false);
              history.push(formatMessage({ 'id': "menu.home.path" }));
            }}>
              <TolLogoVertNoir className="w-48" />
            </button>
            <div className="-mr-2">
              <button
                onClick={() => props.setIsMobileMenuVisible(false)}
                type="button" className="bg-veroverde-base rounded-md p-2 inline-flex items-center justify-center text-white hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-veroverde-base"
              >
                <span className="sr-only">Close menu</span>
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap={"round"} strokeLinejoin={"round"} strokeWidth={"2"} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
          <div className="mt-6">
            <nav className="grid gap-y-8">
              {props.mobileMenu}
            </nav>
          </div>
        </div>
      </div>
    </div>

  );
}

function Footer(props) {
  return (
      <React.Fragment>
        <div className="p-12 text-cgrey-65 text-sm footer">
          <FormattedMessage
              id="home.warning"
              values={{
                p: (...content) => <p className="text">{content}</p>,
                legal: (...content) => <FormattedMessage id="page_link.investment">
                  {url => <a target={'blank'} className={"linkNoFontSize"} href={"https://treesoflives.com/investissement-pour-le-futur/#fund-legal-docs"}>{content}</a>}
                </FormattedMessage>,
              }}
          />
          <br/>
          <FormattedMessage id="footer.copyright" />
        </div>
      </React.Fragment>
  );
}

export function AppFrame(props) {
  const { formatMessage } = useIntl();
  const history = useHistory();

  // menu model used: https://tailwindui.com/components/marketing/elements/headers#component-1c0390a81b16cf5028aeff08be955766
  return (
    <div className="bg-beige-base min-h-screen">
      <div className="relative bg-greentol-base">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-6 lg:justify-start lg:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <button onClick={() => history.push(formatMessage({ 'id': "menu.home.path" }))}>
                <span className="sr-only">TreesOfLives</span>
                <TolLogo className="h-10" />
              </button>
            </div>
            <div className="-mr-2 -my-2 lg:hidden">
              <button
                type="button"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-veroverde-base hover:text-greentol-base hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-veroverde-base"
                onClick={() => props.setIsMobileMenuVisible(true)}
              >
                <span className="sr-only">Open menu</span>
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap={"round"} strokeLinejoin={"round"} strokeWidth={"2"} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
            </div>
            <nav className="hidden lg:flex space-x-10 mt-6">
              {props.menu}
            </nav>
          </div>
        </div>

        {props.isMobileMenuVisible &&
          <MobileMenu
            mobileMenu={props.mobileMenu}
            setIsMobileMenuVisible={props.setIsMobileMenuVisible} />}
      </div>

      { props.contents}

      <Footer />
    </div>
  );
}