
export function MessageModal(props) {

    let css1, css2;
    // something is bugged with this
    if(props.modalVisible) {
        css1="opacity-75";
        css2="opacity-100 translate-y-0 sm:scale-100";
    } else {
        css1="opacity-0";
        css2="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95";
    }

    return (
<div className="fixed z-10 inset-0 overflow-y-auto">
  <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    {/*
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    */ }
    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
      <div className={css1 + " absolute inset-0 bg-gray-500"}></div>
    </div>

    {/* This element is to trick the browser into centering the modal contents. */ }
    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    {/*
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    */ }
    <div className={css2 + " inline-block align-top bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"} role="dialog" aria-modal="true" aria-labelledby="modal-headline">
      <div className="sm:flex sm:items-start">
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 className="text-lg leading-6 font-medium text-veroverde-base" id="modal-headline">
            { props.title }
          </h3>
          <div className="mt-2">
            { props.content }
          </div>
          {
            props.message &&
          <div className="mt-2 text-gray-500">
          { props.message }
          </div>
          }
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => props.setModalVisible(false)}>
          OK
        </button>
      </div>
    </div>
  </div>
</div>
    );
}