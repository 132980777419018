import {Route, Switch} from "react-router-dom";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import Loadable from 'react-loadable';
import {Loading} from "./components/Loading";
import NotFound from "./containers/NotFound";
import React from "react";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

function loading() {
    return <Loading/>;
}

const LoadableHome = Loadable({
    loader: () => import("./containers/Home"),
    loading: loading
});
const LoadableInvestments = Loadable({
    loader: () => import("./containers/Investments"),
    loading: loading
});
/*const LoadableSignup = Loadable({
    loader: () => import("./containers/auth/Signup"),
    loading: loading
});*/
const LoadableLogin = Loadable({
    loader: () => import("./containers/auth/Login"),
    loading: loading
});
const LoadableReset = Loadable({
    loader: () => import("./containers/auth/ResetPassword"),
    loading: loading
});
const LoadableProfile = Loadable({
    loader: () => import("./containers/Profile"),
    loading: loading
});
const LoadableInvestForm = Loadable({
    loader: () => import("./containers/InvestForm"),
    loading: loading
});
const LoadableDocuments = Loadable({
    loader: () => import("./containers/Documents"),
    loading: loading
});
const LoadableProjects = Loadable({
    loader: () => import("./containers/Projects"),
    loading: loading
});
const LoadableCarbon = Loadable({
    loader: () => import("./containers/Carbon"),
    loading: loading
});
const LoadableImpact = Loadable({
    loader: () => import("./containers/Impact"),
    loading: loading
});

export default function Routes(props) {
    return (
        <Switch>
            <UnauthenticatedRoute exact path="/login" component={LoadableLogin} locale="fr"/>
            <UnauthenticatedRoute exact path="/en/login" component={LoadableLogin} locale="en"/>
            <UnauthenticatedRoute exact path="/login/reset" component={LoadableReset} locale="fr"/>
            <UnauthenticatedRoute exact path="/en/login/reset" component={LoadableReset} locale="en"/>
            {
                // <UnauthenticatedRoute exact path="/signup" component={LoadableSignup} locale="fr" />
            }
            <AuthenticatedRoute exact path="/" component={LoadableHome} locale="fr" handleLogout={props.handleLogout}/>
            <AuthenticatedRoute exact path="/en" component={LoadableHome} locale="en"
                                handleLogout={props.handleLogout}/>
            <AuthenticatedRoute exact path="/investissements" component={LoadableInvestments} locale="fr"
                                handleLogout={props.handleLogout}/>
            <AuthenticatedRoute exact path="/en/investments" component={LoadableInvestments} locale="en"
                                handleLogout={props.handleLogout}/>
            <AuthenticatedRoute exact path="/profil" component={LoadableProfile} locale="fr"
                                handleLogout={props.handleLogout}/>
            <AuthenticatedRoute exact path="/en/profile" component={LoadableProfile} locale="en"
                                handleLogout={props.handleLogout}/>
            <AuthenticatedRoute exact path="/impact" component={LoadableImpact} locale="fr"
                                handleLogout={props.handleLogout}/>
            <AuthenticatedRoute exact path="/en/impact" component={LoadableImpact} locale="en"
                                handleLogout={props.handleLogout}/>
            <AuthenticatedRoute exact path="/investform" component={LoadableInvestForm} locale="fr"
                                handleLogout={props.handleLogout}/>
            <AuthenticatedRoute exact path="/documents" component={LoadableDocuments} locale="fr"
                                handleLogout={props.handleLogout}/>
            <AuthenticatedRoute exact path="/en/documents" component={LoadableDocuments} locale="en"
                                handleLogout={props.handleLogout}/>
            <AuthenticatedRoute exact path="/carbon" component={LoadableCarbon} locale="fr"
                                handleLogout={props.handleLogout}/>
            <AuthenticatedRoute exact path="/en/carbon" component={LoadableCarbon} locale="en"
                                handleLogout={props.handleLogout}/>
            <AuthenticatedRoute exact path="/projects" component={LoadableProjects} locale="fr"
                                handleLogout={props.handleLogout}/>
            <AuthenticatedRoute exact path="/en/projects" component={LoadableProjects} locale="en"
                                handleLogout={props.handleLogout}/>
            {/* Finally, catch all unmatched routes */}
            <Route>
                <NotFound/>
            </Route>
        </Switch>
    );
}