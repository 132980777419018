const en = {
    hello_world: "Hello world !",
  
    "loading": "Loading",
    "error.title": "Error!",
    "error.message": "An error occurred while attempting to perform this action.",
    "menu.home.path": "/en",
    "menu.overview.label": "Overview",
    "menu.overview.path": "/en",
    "menu.impact.label": "Impacts",
    "menu.impact.path": "/en/impact",
    "menu.docs.label": "Documents",
    "menu.docs.path": "/en/documents",
    "menu.carbon.label": "Carbon neutral",
    "menu.carbon.path": "/en/carbon",
    "menu.projects.label": "Projects",
    "menu.projects.path": "/en/projects",
    "menu.investments.label": "Investments",
    "menu.investments.path": "/en/investments",
    "menu.profile.label": "Profile",
    "menu.profile.path": "/en/profile",
    "menu.logout.label": "Logout",

    "lang": "en",
    "language.other": "Français",

    "page_link.investment": "/en/investment-for-the-future",

    "login.url": "/en/login",
    "login.url.otherlang": "/login",
    "signup.url": "/en/signup",
    "signup.url.otherlang": "/signup",
    "resetpwd.url.otherlang": "/login/reset",
    "login.title": "Investor Space",
    "login.login_divider": "Connect to your account",
    "login.fields.email": "Email address",
    "login.fields.password": "Password",
    "login.not_member_yet": "Not an investor yet?",
    "login.not_member_yet.url": "https://treesoflives.com/en/invest-as-an-individual/",
    "login.forgot_pwd": "Forgot your password?",
    "login.signin": "Sign in",
    "login.newPassword.desc": "Please choose a new password:",
    "login.newPassword.strength.label": "Your password must respect the following rules:",
    "login.newPassword.strength.1.label": "Be at least 12 characters long",
    "login.newPassword.strength.2.label": "Contains at least one lower case and one upper case character",
    "login.newPassword.strength.3.label": "Contains at least one digit",
    "login.newPassword.strength.4.label": "Both passwords must be equal",
    "login.fields.newPassword": "New password",
    "login.fields.newPassword2": "Reenter your new password",
    "login.update_password": "Update password",
    
    "pwd_reset.description": "If you forgot your password, please enter your email below and we will send you a confirmation code to your email address.",
    "pwd_reset.u_know_pwd": "You know your password?",
    "pwd_reset.reset_divider": "Reset password",
    "pwd_reset.send_label": "Send reset code",
    "pwd_reset.resetpwd_label": "Reset password",

    "pwd_reset.resend_code.label": "Resend code",
    "pwd_reset.fields.code.label": "Confirmation code",
    "pwd_reset.fields.code.placeholder": "Please check your email for the code",
    "pwd_reset.success.message": "Your password has been reset.",
    "pwd_reset.login_new_pwd": "Sign in with your new credentials",
 
    "home.title": "Statement of your{br}investment over {years} year(s)",
    "home.openlead.header": "Here are the steps you can expect when processing your investment request.",
    "home.openlead.steps.1.title": "Appointment with a broker",
    "home.openlead.steps.1.desc": "We are working with an exempt market broker who verifies the eligibility of any new investor. The broker will review your investor profile during this phone call to ensure that the investment matches your profile and your objectives. It will also be an opportunity for you to ask any final questions you have about the fund. ",
    "home.openlead.steps.2.title": "Documentation received",
    "home.openlead.steps.2.desc": "Your document has been received and will be subject to a compliance check.",
    "home.openlead.steps.3.title": "Request validated",
    "home.openlead.steps.3.desc": "Your case has been approved by compliance.",
    "home.openlead.steps.4.title": "Opening of the Computershare account",
    "home.openlead.steps.4.desc": "You will be prompted to register for an account with Computershare.",
    "home.openlead.steps.5.title": "Funds received",
    "home.openlead.steps.5.desc": "Congratulations, your funds have been received and you are now an investor in TreesOfLives.",
    "home.openlead.steps.6.title": "Receipt",
    "home.openlead.steps.6.desc": "A receipt will be issued to you shortly and you will receive it by mail.",
    "home.warning": "<p>*Disclaimer: Expected yields at all levels are based on data from similar projects carried out by Viridis Terra or its partners in the past in the same general areas where Viridis Terra carries out its restoration activities. However, yields are not guaranteed and may vary considerably due to various factors including climatic conditions and the choice of forestry and/or agroforestry systems by landowners. In order to optimize your impacts and returns, we encourage you to maintain your investment in TreesOfLives for at least five years, ideally as long as possible. See our <legal>legal documentation</legal> for full details.</p>",

    "statsblock.elem.1.label": "Value of your investment",
    "statsblock.elem.2.label": "Carbon sequestration",
    "statsblock.elem.3.label": "Natural capital gains",
    "statsblock.elem.4.label": "Economic damage avoided",
    "statsblock.elem.5.label": "Landowners' income",
    "statsblock.elem.6.label": "Families involved",
    "statsblock.elem.7.label": "Hectares restored",
    "statsblock.elem.8.label": "Protected hectares",
    "statsblock.elem.9.label": "Value of biological assets",
    "statsblock.elem.10.label": "Money distributed",
    "statsblock.stats.tocome": "soon available",

    "home.projections.you": "For you",
    "home.projections.community": "For communities",
    "home.projections.planet": "For the planet",

    "home.projections.title": "Projections",
    "home.projections.subtitle1": "Carbon",
    "home.projections.subtitle2": "Financial",
    "home.projections.subtitle3": "Biodiversity",
    "home.projections.subtitle4": "Social",
    "home.projections.1.title": "Carbon sequestration",
    "home.projections.1.description": "This is the number of tonnes of CO2 equivalent that you have helped to sequester, and which also corresponds to your quantity of certified carbon offset credits. The sequestered carbon comes from new forests and protected forests, thanks to your investment.",
    "home.projections.2.title": "Natural capital gains",
    "home.projections.2.description": "Natural capital represents the economic value of goods and services derived from nature. Wood, food, biodiversity, water and air quality are examples. The gain in natural capital is negative during the first years since the preliminary land preparation work requires removing some existing vegetation. As new forests grow and the ecosystem regenerates, natural capital gains increase.",
    "home.projections.3.title": "Economic damage avoided",
    "home.projections.3.description": "This graph shows the amount corresponding to the environmental damages avoided, thanks to your investment. The damage caused by climate change translates into negative economic value. These can include effects on human health, property damage from floods and natural disasters, or lower agricultural productivity.",
    "home.projections.4.title": "Landowners' income",
    "home.projections.4.description": "This amount corresponds to the income that the landowners involved in our projects receive, depending on your investment. By comparison, the average annual salary in Peru was US $4,693.32 in 2021. Source: https://preciosmundi.com/peru/precio-vivienda-salarios",
    "home.projections.5.title": "Families involved",
    "home.projections.5.description": "Every time we restore land, a farmer land-owner and his family benefit. Whether it is through higher income or of children pursuing studies, entire families benefit from the impact of the projects.",
    "home.projections.6.title": "Money distributed",
    "home.projections.6.description": "Each year, you will receive cash distributions (also known as dividends) corresponding to your return on investment from project profits. The discounted return on investment (ROI) and internal rate of return (IRR) are calculated based on this distributed sum.",
    "home.projections.6.note1": "IRR (Internal Rate of Return) : ",
    "home.projections.6.note2": "AROI (Annualized Return on Investment) : ",
    "home.projections.6.note3": "DCF (Discounted Cash Flow) : ",
    "home.projections.7.title": "Value of biological assets",
    "home.projections.7.description": "By investing in TreesOfLives, you get units (shares) of the limited partnership that owns a co-ownership with the landowners on the tree-related biological assets established on the restored land. These trees produce goods and services of commercial value over time, thus creating monetary value in the future. We considered the discounted cash flow formula with a conservative 20% rate to calculate the value of the assets. When you sell your units, the value of the biological assets could be a starting point for negotiations with other investors eventually.",

    "home.messages.title": "Your messages",
    "home.messages.1.title": "Welcome to TreesOfLives!",
    "home.messages.1.desc": "<p>Welcome to TreesOfLives. You are among the first investors to have made a concrete gesture in the fight against climate change. For that we thank you.</p><p>Many sections of this page are not yet completed and we are working tirelessly to bring you exciting features. Please let us what you think and what you'd like to see!</p>",
    "home.messages.1.cta": "Let us know what you think!",

    "impacts.title": "Impacts SDGs (UN Sustainable Development Goals)",
    "impacts.description": "Through our degraded land restoration projects, we strive to have the most significant possible impact on the UN's 17 Sustainable Development Goals, which are essential for a world without poverty, the protection of the planet and the prosperity for all.",
    "impacts.description2": "\"The Sustainable Development Goals are a call for action by all countries – poor, rich and middle-income – to promote prosperity while protecting the planet. They recognize that ending poverty must go hand-in-hand with strategies that build economic growth and address a range of social needs including education, health, social protection, and job opportunities while tackling climate change and environmental protection\". – Source: United Nations Website",
    "impacts.odd1.description": "<p><label1>0.1</label1> family involved</p><p>Landholder revenues: <label2>$2 950</label2></p><p>Landholder business profit over 30 years: <label3>$67 360</label3></p><p>Increase in family income: to come</p>",
    "impacts.odd2.description": "<p>2X increase in land productivity</p><p><label1>0,07</label1> ha family gardens</p><p>50 edible species planted</p>",
    "impacts.odd3.description": "<p>Additional family income that can be invested in care: to come</p><p>Decrease in the number of people with respiratory disorders</p>",
    "impacts.odd4.description": "<p><label1>4.8</label1> hours of training / capacity building for sustainable management per year</p><p>% increase in children (girls/boys) attending school: to come</p>",
    "impacts.odd5.description": "<p><label1>0.51</label1> woman signing contracts</p><p><label1>0.51</label1> woman trained in agroforestry</p><p><label1>0.51</label1> people receive Positive Masculinity Training</p><p>Increase of women in position of power: to come</p>",
    "impacts.odd6.description": "<p>Increase in access and quality of water</p><p><label1>0.76</label1> ha of wetlands and riparian areas</p>",
    "impacts.odd7.description": "<p>Target: 100% of energy used is renewable</p><p>When inevitable, GHG emitted are directly compensated by carbon credits (0.2 T CO2 eq/ha)</p>",
    "impacts.odd8.description": "<p><label1>1.09</label1> jobs created</p><p>Number of associations created or reinforced: to come</p>",
    "impacts.odd9.description": "<p>Leads to an additional <label1>$660</label1> invested in infrastructure for biotechnology production</p><p>Increase in access to cellular grid in remote areas: to come</p>",
    "impacts.odd10.description": "<p>Over 8 Indigenous communities as partners benefiting from project</p><p>Reduced salary disparities: to come</p>",
    "impacts.odd11.description": "<p>Decreased urban migration: to come</p><p><label1>100</label1> additional trees given to local communities, <label2>400</label2> m2 of city greened</p>",
    "impacts.odd12.description": "<p>Products issued of forests and agroforests</p><p>100% geotraced</p><p>100% zero deforestation</p><p>100% certified; whether it be carbon credits (VERRA), bioenergy(SBP), wood (FSC), agriproducts (Rainforest Alliance, Fairtrade)</p>",
    "impacts.odd13.description": "<p><label1>1000</label1> tons CO2 sequestered and certified over 30 years in restored landscapes and avoided from being released by forest preservation</p>",
    "impacts.odd14.description": "<p>Reduction in the sediment load deposited in coastal areas due to control of soil erosion through restoration and reforestation: to come</p>",
    "impacts.odd15.description": "<p><label1>0.7</label1> ha restored in productive systems</p><p><label2>0.3</label2> ha restored in natural forest ecosystems</p><p><label1>0.7</label1> ha of natural forest preserved</p><p>Increase of the natural capital value by a mean of <label3>4 786 $</label3> per year</p>",
    "impacts.odd16.description": "<p>100% of complaints received, treated, resolved with satisfaction of all parties</p><p>Nb of persons trained in (land) conflict resolution: to come</p><p>All employees of VTP and VTI trained in anti-corruption matters</p><p>Anti-corruption due diligence realized on all partners</p>",
    "impacts.odd17.description": "<p>Strong partnerships with over 40 institutions of the public, private sectors and civil society</p><p>Lead to an additional <label1>1000 $</label1> invested in International Development in countries where risk is currently too high to bring IFLR</p>",

    "projects.title": "Projects",
    "projects.activities.title": "Current steps",
    "projects.description": "As the projects evolve, you will have access to photos of the forests, agroforests and our field activities.",
    "projects.activity1.title": "Contracts signing with landholders",
    "projects.activity2.title": "Inventory of restored lands",
    "projects.activity3.title": "Selection of land management models by landholders",
    "projects.activity4.title": "Production of biotechnologies",
    "projects.activity5.title": "Restoration and reforestation field operations",
    "projects.activity6.title": "Regular monitoring of asset growth, sale of commodities and project impacts",
    "projects.activity1.description": "One of the essential steps before the start of projects is signing contracts stipulating the commitments of TreesOfLives, Viridis Terra, and landholders. It ensures optimal operation and good management of expectations between stakeholders.",
    "projects.activity2.description": "This step aims at understanding the current use and land use capacity. To achieve this, our team makes an inventory of the vegetation and a soil hydrological analysis (water circulation in the soil). Thus, we can determine which of our land management models are best suited to that specific location.",
    "projects.activity3.description": "Once the land use capacity is known, a dialogue is initiated between the landholder and technical professionals to determine which model to implement. Together with the landowners, we choose the best management systems for their land. We consider the type of soil, the location and the cultivation methods favored by the farmer.",
    "projects.activity4.description": "Once we can determine the plant species, their quantity and the needs for organic inputs (soil, compost and fertilizer), we can plan the supply that will allow us to implement the land management models. We source from various suppliers with advanced knowledge and technologies in the field.",
    "projects.activity5.description": "This step is where we implement the land-use models. This is the concrete work of planting trees and restoring the land begins. Typically, we have to do this step before the rainy season to ensure a successful outcome.",
    "projects.activity6.description": "When a project is well established, our field team will carry out regular monitoring so that the forests are growing as planned. In addition, TreesOfLives will sell the commodities from the projects to generate your return on investment.",
    "projects.news.title": "News",
    "projects.map.title": "Work in progress map",
    "projects.pictures.title": "Pictures",

    "projects.videos.title": "Videos",
    "video.motion_design": "https://s3.ca-central-1.amazonaws.com/documents.treesoflives.com/DronesNuevoPalestinaJuin2021.mp4",
    "video.motion_design2": "https://s3.ca-central-1.amazonaws.com/documents.treesoflives.com/DronesSanAlejandroJuin2021.mp4",
    "projects.picture1.description": "Accumulate the organic mass on the soil in the sample plot",
    "projects.picture2.description": "Indication of the plot with the compass",
    "projects.picture3.description": "The head of the brigade (Gustavo Dondo) giving directions for the inventory",
    "investments.title": "Investments",
    "investments.list.header.amount": "Amount",
    "investments.list.header.units": "Units",
    "investments.list.header.state": "State",
    "investments.list.header.type": "Type",
    "investments.list.header.fund": "Fund",

    "investments.mardiinfo.warning": "Please note: At least one of your investments is registered by Mardi.info with ComputerShare. The return on these investments could be different from the TreesOfLives fund, as it is registered in a mutual fund. For more details, please contact Dany Bergeron at Mardi.info (438-882-4464).",

    "investments.type.RRSP": "RRSP",
    "investments.type.TFSA": "TFSA",
    "investments.type.UNREGISTERED": "Unregistered",

    "investments.state.EXTERNAL": "Management externally",
    "investments.state.FUNDS_TRANSFER_TODO": "Transfer pending",
    "investments.state.FUNDS_TRANSFER_IN_PROGRESS": "Transfer in progress",
    "investments.state.FUNDS_TRANSFER_DONE": "Transfer complete",
    "investments.state.WAITING_FOR_INVESTOR_TRANSFER": "Waiting for funds",

    "investment.newinvestment.title": "Do you want to{br}<green>invest</green> again?",
    "investment.newinvestment.link": "https://www.treesoflives.com/en/investment-for-the-future/",
    "investment.newinvestment.button.label": "Invest again",

    "profile.sections.profile": "Profile",
    "profile.sections.email": "Email",
    "profile.sections.password": "Password",
    "profile.sections.notifications": "Notifications",
    "profile.profile.contact_info.title": "Contact information",

    "profile.profile.fields.given_name": "First name",
    "profile.profile.fields.family_name": "Last name",
    "profile.profile.fields.email": "Current email",
    "profile.profile.fields.phone_number": "Phone number",
    "profile.profile.fields.custom:phone_landline": "Landline number",
    "profile.profile.fields.street_address": "Address",
    "profile.profile.fields.city": "City",
    "profile.profile.fields.state": "Province / State",
    "profile.profile.fields.zip": "Postal code / Zip code",
    "profile.profile.fields.country": "Country",
    "profile.profile.fields.country.values.canada": "Canada",
    "profile.profile.fields.country.values.usa": "United States",
    "profile.profile.fields.locale": "Language of communication",

    "profile.profile.fields.state.values.provinces.AB": "Alberta",
    "profile.profile.fields.state.values.provinces.BC": "British Columbia",
    "profile.profile.fields.state.values.provinces.MB": "Manitoba",
    "profile.profile.fields.state.values.provinces.NB": "New Brunswick",
    "profile.profile.fields.state.values.provinces.NL": "Newfoundland and Labrador",
    "profile.profile.fields.state.values.provinces.NT": "Northwest Territories",
    "profile.profile.fields.state.values.provinces.NS": "Nova Scotia",
    "profile.profile.fields.state.values.provinces.NU": "Nunavut",
    "profile.profile.fields.state.values.provinces.ON": "Ontario",
    "profile.profile.fields.state.values.provinces.PE": "Prince Edward Island",
    "profile.profile.fields.state.values.provinces.QC": "Quebec",
    "profile.profile.fields.state.values.provinces.SK": "Saskatchewan",
    "profile.profile.fields.state.values.provinces.YT": "Yukon",

    "profile.profile.address.title": "Address",
    "profile.profile.other.title": "Other",

    "profile.profile.update_button": "Update",

    "profile.profile.email.resend_code": "Resend code",
    "profile.profile.fields.newEmail": "New email",
    "profile.profile.fields.confirmNewEmail": "New email confirmation",
    "profile.profile.email.email_no_match": "TThe new email and its confirmation must have a value and match.",
    "profile.profile.email.email_format_invalid": "Invalid email format.",
    "profile.profile.email.code_sent_desc": "A confirmation code has been sent to the address you provided. Please enter it below.",
    "profile.profile.fields.emailCode": "Code received in your email",
    "profile.profile.fields.emailCode_invalid": "The code provided is invalid. Please make sure you have provided the right code.",
    "profile.profile.email.change_success": "Your email address has been updated successfully.",

    "profile.profile.fields.oldPassword": "Old password",
    "profile.profile.fields.password": "New password",
    "profile.profile.fields.confirmPassword": "Confirm the new password",
    "profile.profile.error.phone": "The phone number format is invalid. Phone numbers must follow these formatting rules: A phone number must start with a plus (+) sign, followed immediately by the country code. A phone number can only contain the + sign and digits. You must remove any other characters from a phone number, such as parentheses, spaces, or dashes (-) before submitting the value to the service. For example, a United States-based phone number must follow this format: +14325551212.",
    "profile.profile.change_success": "Your profile has been successfully updated.",
    "profile.profile.password.change_success": "Your password has been successfully updated.",

    "documents.available_soon": "Available soon",

    "documents.lookingforfund-legal": "Looking for the TreesOfLives impact fund legal documents?",
    "documents.lookingforfund-legal.link": "https://treesoflives.com/en/investment-for-the-future#fund-legal-docs",

    "footer.consent": "By using the Website or by logging in an account on the Platform, you accept and agree to be bound and comply with our <legal>terms of use and privacy notices</legal>.",
    "footer.consent.legal_link": "https://treesoflives.com/en/legal-notices",
    "footer.copyright": "© 2021, TreesOfLives Inc. All Rights Reserved.",

    "carbonchart.title": "Your 30 years emissions",
    "carbonchart2.title": "The road to carbon neutrality!",
    "carbonchart.description": "When you invest in TreesOfLives, you help remove significant amounts of greenhouse gases from the atmosphere, a portion of which can be used to offset your emissions.",
    "carbonchart.description2": "You can follow the accumulation of carbon credits associated with your investment using this graph.",
    "carbonchart.amount_to_invest": "Invested amount",
    "carbonchart.more": "More",
    "carbonchart.less": "Less",
    "carbonchart.ca": "Canada",
    "carbonchart.us": "United States",
    "carbonchart.eu": "Europe",
    "carbonchart.age.label": "Your age: ",
    "carbonchart.production.label":
        "Your average annual emissions (tons of CO2): ",
}

export default en;