import { Redirect, Route } from "react-router-dom";

import {IntlProvider} from 'react-intl';
import React, { useContext } from "react";
import en from "../lang/en";
import fr from "../lang/fr";
import { useAppContext } from "../libs/contextLib";
import { GlobalContext } from "../libs/globalContext";

export default function UnauthenticatedRoute({ children, locale, ...rest }) {
    const { isAuthenticated } = useAppContext();
    const [globalState, dispatch] = useContext(GlobalContext);
    globalState.content.locale = locale;
    // dispatch({type: "UPDATE_OBJECT", payload: globalState});

    const redirect = querystring("redirect");
    return (
      <IntlProvider messages={locale === "fr" ? fr : en} locale={locale} defaultLocale="en">
      <Route {...rest}>
        {!isAuthenticated ? (
          children
        ) : (
          <Redirect to={redirect === "" || redirect === null ? "/" : redirect} />
        )}
      </Route>
      </IntlProvider>
    );
  }
  
function querystring(name, url = window.location.href) {
    name = name.replace(/[[]]/g, "\\$&");
  
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
    const results = regex.exec(url);
  
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return "";
    }
  
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }