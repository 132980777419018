import React from "react";
import { reportSentryError } from "../libs/errorLib";
import "./ErrorBoundary.css";
import { ReactComponent as TolLogo } from '../logos/TOL_logo_horizontal_vert_noir.svg';

export default class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    reportSentryError(error, errorInfo);
  }

  render() {
    return this.state.hasError ? (
    <div className="min-h-screen flex items-center justify-center bg-beige-base py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-lg w-full">
        <TolLogo />
        <h2 className="mt-6 text-center text-2xl leading-9 font-extrabold text-gray-900">
          Sorry, there was a problem<br />loading this page.
        </h2>
        <p className="mt-3 text-center">Our team has been notified. You can try reloading the page. If the problem persists, you can contact us at invest@treesoflives.com.</p>
        <h2 className="mt-8 text-center text-2xl leading-9 font-extrabold text-gray-900">
          Désolé, il y a eu un problème lors du chargement de cette page.
        </h2>
        <p className="mt-3 text-center">Notre équipe a été alertée. Vous pouvez essayer de recharger la page. Si le problème persiste, vous pouvez nous contacter à invest@treesoflives.com.</p>
      </div>
    </div>
    ) : (
      this.props.children
    );
  }
}