const config = {
  s3: {
    REGION: "us-east-1",
    BUCKET: process.env.REACT_APP_KYC_BUCKET
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: process.env.REACT_APP_APIGATEWAY_URL
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID
  },
  MAX_ATTACHMENT_SIZE: 5000000
};

export default config;